<template>
  <div>
    <nav-bar>
      <div class="edition-center" v-if="orderGoods.length">
        <div class="page-container">
          <!-- 订单表格 ----------------------------------- -->
          <div class="order-table">
            <div class="order-list">
              <div class="order-item">
                <div class="item-header">
                  <div>números {{ orderDetail.id }}</div>
                  <div>
                    <span>
                      {{
                        orderDetail.createdTime &&
                        orderDetail.createdTime.split(' ')[0]
                      }}
                    </span>
                  </div>
                </div>
                <div class="item-content">
                  <div class="item-goods">
                    <div
                      class="item-goods-list"
                      v-for="(goods, goodIndex) in orderGoods"
                      :key="goodIndex"
                    >
                      <div class="item-goods-flex">
                        <div style="flex: none; margin-right: 20px">
                          <div class="list-item">
                            <div class="flex">
                              <div
                                disable
                                class="select-icon flex align-center"
                                @click="selectGoods(goods)"
                                v-if="!goods.afterSaleStatus"
                              >
                                <img
                                  v-if="goods.selected"
                                  src="@/assets/carts/checkActive.png"
                                  alt=""
                                />
                                <img
                                  v-else
                                  src="@/assets/carts/check.png"
                                  alt=""
                                />
                              </div>
                              <div
                                class="select-icon flex align-center"
                                v-else
                              ></div>
                              <div class="item-img">
                                <img
                                  :src="goods.goodsCoverUrl[0].url | filterPicture"
                                  alt=""
                                />
                              </div>
                            </div>
                            <div class="item-text">
                              <div class="item-name">
                                <span class="goods-sn">
                                  {{ goods.goodsSn }}
                                </span>
                                <span>{{ goods.goodsName }}</span>
                              </div>
                              <div class="specs">
                                <span
                                  v-for="(item, index) in goods.specs"
                                  :key="index"
                                >
                                  {{ (index === 0 ? '' : ' / ') + item.name }} :
                                  {{ item.value }}
                                </span>
                              </div>
                              <div class="barCode">
                                Código de barras：{{ goods.skuBarCode }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="price">
                          COP {{ goods.goodsPrice | filterPrice }}
                        </div>
                        <div>{{ goods.buyNums }}</div>
                        <div>{{ goods.deliveryNumber }}</div>
                        <div class="price">
                          COP {{ goods.buyPriceOriginal | filterPrice }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="checkBox flex align-center flex-between">
            <div
              class="select-icon flex align-center"
              @click="reverseAllSelected"
            >
              <img
                src="@/assets/carts/checkActive.png"
                alt=""
                v-if="allSelected"
              />
              <img v-else src="@/assets/carts/check.png" alt="" />
              <span>Selección completa</span>
            </div>
            <el-button
              class="btn"
              :disabled="!active"
              type="success"
              @click="handleConfirm"
            >
              ir después de la venta
            </el-button>
          </div>
        </div>
        <div></div>
      </div>
      <div v-else class="loadingVue flex">
        <loadingVue />
      </div>
    </nav-bar>
  </div>
</template>

<script>
import loadingVue from '../../../components/loading.vue'
import navBar from '@/components/navBar/index.vue'
export default {
  components: {
    navBar,
    loadingVue
  },

  data() {
    return {
      id: '',
      orderDetail: [],
      certificateType: {
        RECEIPT: 'Recibos',
        INVOICE: 'Facturas (19% IVA)'
      },
      settlementType: [
        'Pago inmediato',
        'Pago contra entrega',
        'Pago con crédito'
      ],

      orderAfterStatus: '',
      orderGoods: [],
      selectStatus: '',
      active: true
    }
  },

  created() {
    
    if(!this.$route.params.id) {
      this.$router.back()
      return
    }
    this.getOrderDetail()
  },
  computed: {
    allSelected() {
      const noSelectIndex = this.orderGoods.findIndex((item) => !item.selected)

      return noSelectIndex === -1
    }
  },
  methods: {
    getOrderDetail() {
      this.$axios
        .post('/childOrder/detail/' + this.$route.params.id)
        .then((res) => {
          this.orderDetail = res.data.result
          this.orderGoods = this.orderDetail.orderGoods.filter(
            (item) => !item.isAfterSale
          )
          if (this.orderGoods.length) {
            this.orderGoods.map((item) => {
              this.$set(item, 'selected', false)
              item.goodsCoverUrl = JSON.parse(item.goodsCoverUrl)
              return item
            })
          }
          const time = 2000
          setTimeout(() => {
            if(!this.orderGoods.length) {
              this.$message.info('Actualmente no hay productos postventa.')
              this.$router.back()
            }
          }, time)
          console.log('订单详情-', this.orderGoods)
        })
    },

    handleConfirm() {
      let list = []
      this.orderGoods.forEach((item) => {
        if (!item.afterSaleStatus) {
          if (item.selected) {
            const params = {
              childOrderId: item.childOrderId,
              id: item.id
            }
            list.push(params)
          }
        }
      })
      if (list.length) {
        this.$router.push({
          path: '/refund-apply',
          name: 'refund-apply',
          params: { orderGoodsId: list }
        })
      } else {
        this.$message.info('por favor elige')
      }
    },
    selectGoods(option) {
      option.selected = !option.selected
      console.log('option', option)
    },
    reverseAllSelected() {
      const reverseSelected = !this.allSelected

      this.orderGoods.map((item) => (item.selected = reverseSelected))
    }
  }
}
</script>

<style lang="scss" scoped>
.checkBox {
  height: 56px;
  padding-left: 20px;
  margin-top: 20px;
  background-color: #e7e7e7;
  .select-icon {
    flex: none;
    width: 20px;
    margin-right: 20px;
    cursor: pointer;
    img {
      width: 100%;
    }
    span {
      margin-left: 10px;
      white-space: nowrap;
    }
  }
  .btn {
    width: 200px;
    height: 56px;
    color: #fff;
    background: #8acf66;
  }
}
img {
  display: block;
}

.edition-center {
  width: 1200px;
  margin: 0 auto;
}

.page-container {
  margin: 40px 0;
  .title-bar {
    font-size: 20px;
    font-weight: bold;
  }
  .order-bar {
    margin-top: 20px;
    img {
      width: 100%;
    }
    .order-info {
      display: flex;
      .order-info-left {
        width: 360px;
        padding: 20px;
        background: #f9fbf9;
        border-right: 1px solid #e7e7e7;
        .title {
          margin-bottom: 10px;
          font-weight: bold;
        }
        p {
          font-size: 18px;
        }
      }
      .order-info-right {
        width: 600px;
        padding: 20px 40px;
        color: #979797;
        font-size: 18px;
        > div {
          display: flex;
          justify-content: space-between;
          padding: 3px 0;
          color: #606060;
        }
      }
    }
  }

  .add {
    width: 120px;
    margin: 20px 0;
    img {
      width: 100%;
    }
  }

  .order-table {
    margin-top: 40px;
  }

  .price-container {
    padding: 40px 0;
    color: #282828;
    font-size: 20px;
    text-align: right;
    p {
      padding: 5px 0;
    }
    .value {
      font-size: 18px;
      font-weight: bold;
    }
  }
}
</style>

<style lang="scss" scoped>
$goods-item-height: 118px;

.price {
  color: #d43a57;
}

.header {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #979797;
  padding: 10px 0 10px 20px;
  background: #f9fbf9;
  > div {
    flex: 1;
    text-align: center;
  }
}

.order-list {
  .order-item {
    margin-top: 20px;
    .item-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 40px;
      background: #f7fff7;
      font-size: 14px;
      color: #606060;
      padding: 0 20px;
      border: 1px solid #e7e7e7;
    }
    .item-content {
      display: flex;
      font-size: 14px;
      text-align: center;
      border-right: 1px solid #e7e7e7;
      border-bottom: 1px solid #e7e7e7;
      .item-goods {
        flex: 6.5;
        .item-goods-list {
          padding: 10px 0;
          border-bottom: 1px solid #e7e7e7;
          .item-goods-flex {
            display: flex;
            align-items: center;
            height: $goods-item-height;
            > div {
              flex: 1;
            }
          }
        }

        :nth-last-child(1) {
          border-bottom: none;
        }
      }
      > div {
        flex: 1;
        border-left: 1px solid #e7e7e7;
        padding-top: 10px;
        .alignment {
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          ::v-deep .el-button {
            white-space: normal;
          }
        }
        .order-status {
          height: $goods-item-height;
          padding: 10px 0;
          border-bottom: 1px solid #e7e7e7;
          display: flex;
          align-items: center;
          ::v-deep .el-button {
            white-space: normal;
          }
          ::v-deep .el-button + .el-button {
            margin: 0;
          }
        }
        .order-status:nth-last-child(1) {
          border-bottom: none;
        }
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.loadingVue {
  height: 500px !important;
}
.select-icon {
  width: 18px;
  flex: none;
  margin-right: 20px;
  cursor: pointer;
  img {
    width: 100%;
  }
}
.list-item {
  display: flex;
  cursor: pointer;
  padding-left: 20px;
  text-align: initial;
  .item-img {
    width: 80px;
    border-radius: 8px;
    overflow: hidden;
    margin-right: 10px;
    border-radius: 8px;
    img {
      display: block;
      width: 100%;
    }
  }
  .item-text {
    max-width: 220px;
    position: relative;
    .item-name {
      font-size: 14px;
      color: #000;
      margin: 8px 0;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      .goods-sn {
        display: inline-block;
        background: #fcbbbb;
        color: #ffffff;
        padding: 0 5px;
        border-radius: 6px;
        margin-right: 10px;
      }
    }
    .specs,
    .barCode {
      color: #979797;
      font-size: 12px;
      margin: 2px 0;
    }
  }
}
</style>
